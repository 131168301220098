@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNextLTPro-It.otf") format("opentype");
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/AvenirNextLTPro-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}